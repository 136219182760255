import Head from "next/head";
import Link from "next/link";
import {Row, Col} from "react-bootstrap";
import Image from "next/image";

export default function Home() {
    return (
        <div className="container">
            <Head>
                <title>Raman Base</title>
                <meta name="description"
                      content="Raman Base is a new attempt for a global solution for making the published spectral data openly available."/>
                <link rel="icon" href="/favicon.ico"/>
            </Head>

            <Row>
                <Col md="12" className="d-flex justify-content-end gap-3">
                    <Image src="/UPT_compact_en_rgb_15.svg" alt="ISI logo" width={180} height={100}/>
                    <Image src="/Logo_Strategy_ENG_V2.png" alt="Strategy logo" width={180} height={100}/>
                </Col>
            </Row>

            <Row className="mb-5 justify-content-center">
                <Col md="6" className="text-center">
                    <h1 className="display-1 mb-3">Raman Base</h1>
                    <p className="lead">The Ultimate Raman Spectroscopic Database. <br/> Search, Explore, Contribute</p>
                </Col>
            </Row>

            <Row>
                <Col md="6" className="mb-5">
                    <p>Create a personal repository of Raman spectroscopic data, available everywhere, 24/7. Share the
                        data whenever you publish your Raman-based discoveries. Identify spectra by similarity with our
                        search engine. Download spectra shared by other users. Register now and enjoy the full potential
                        of Raman Base.</p>
                    <Link href="/register">
                        <a className="btn btn-primary btn-lg mt-2">Join Raman Base</a>
                    </Link>
                </Col>
                <Col md="6" className="mb-5">
                    <iframe
                        width="100%"
                        height="315"
                        loading="lazy"
                        src="https://www.youtube.com/embed/smkM8_s3TiM"
                        title="Introduction to Raman Base"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </Col>
            </Row>

            <Row>
                <Col md="6" className="order-md-1 mb-5">
                    <p>If you can’t wait to see some spectra, browse the database immediately and use the full-text
                        search to find the spectra of your interest. Then use the spectra viewer to see the peak
                        positions and zoom to the smallest details you want to check. No registration needed, just go
                        and browse the spectra.</p>
                    <Link href="/spectra">
                        <a className="btn btn-outline-primary btn-lg mt-2">Browse spectra</a>
                    </Link>
                </Col>

                <Col md="6" className="order-md-0 mb-5">
                    <Image src="/compare.png" alt="Spectrum" width={800} height={400}/>
                </Col>
            </Row>

            <Row>
                <Col md="6" className="mb-5">
                    <p>Raman Base is a powerful instrument, built with open and transparent science in mind. If you
                        would like to know everything about it, starting with the philosophy behind its creation,
                        through the history of its origin, to the spectrum of possibilities it provides and the plans
                        for future upgrades, go find out more.</p>
                    <Link href="/about">
                        <a className="btn btn-primary btn-lg mt-2">Find out more</a>
                    </Link>
                </Col>
                <Col md="6" className="mb-5">
                    <Image src="/spectrum.png" alt="Spectrum" width={800} height={400}/>
                </Col>
            </Row>
        </div>
    );
}
